<template>
	<div class="panel">
		<div class="flex items-center mb-4 space-x-2">
			<RectangleStackIcon class="h-6 text-mx-gray-500 dark:text-mx-gray-400" />
			<span class="text-sm uppercase text-mx-gray-500 dark:text-mx-gray-400">
				{{ t('account-dashboard-current-invoice.title') }}
			</span>
		</div>
		<div class="grid grid-cols-2 gap-y-2 md:hidden">
			<div class="flex flex-col">
				<span class="text-sm uppercase text-mx-gray-500 dark:text-mx-gray-400">
					{{ t('account-dashboard-current-invoice.table.header.date') }}
				</span>
				<span class="text-lg font-semibold">
					{{ formatDate(invoice?.createdTime, MSDY) }}
				</span>
			</div>
			<div class="flex flex-col">
				<span class="text-sm uppercase text-mx-gray-500 dark:text-mx-gray-400">
					{{ t('account-dashboard-current-invoice.table.header.id') }}
				</span>
				<span class="text-lg font-semibold">
					{{ invoice?.id }}
				</span>
			</div>
			<div class="flex flex-col">
				<span class="text-sm uppercase text-mx-gray-500 dark:text-mx-gray-400">
					{{ t('account-dashboard-current-invoice.table.header.amount') }}
				</span>
				<span class="text-lg font-semibold">
					{{ toCurrencyString(invoice?.totalAmount) }}
				</span>
			</div>
			<div class="flex flex-col">
				<span class="text-sm uppercase text-mx-gray-500 dark:text-mx-gray-400">
					{{ t('account-dashboard-current-invoice.table.header.due-date') }}
				</span>
				<span class="text-lg font-semibold">
					{{ formatDate(invoice?.dueDate, MSDY) }}
				</span>
			</div>
			<div class="flex flex-col">
				<span class="text-sm uppercase text-mx-gray-500 dark:text-mx-gray-400">
					{{ t('account-dashboard-current-invoice.table.header.invoice') }}
				</span>
				<NuxtLink :to="`/billing/invoices/${invoice?.id}`">
					<span class="text-mx-orange uppercase font-semibold link-text hover:bg-mx-gray-100 lg:px-3 py-1.5 rounded-lg dark:hover:bg-mx-green-800">
						{{ t('account-dashboard-current-invoice.buttons.view') }}
					</span>
				</NuxtLink>
			</div>
		</div>
		<div class="hidden -mx-6 lg:-mx-8 md:block">
			<table class="w-full text-left">
				<thead>
					<tr class="text-sm uppercase border-y-2 bg-mx-gray-100 dark:border-y-mx-green-900 dark:bg-mx-green-800">
						<th class="py-4 pl-6 pr-3 font-medium lg:pl-8">
							{{ t('account-dashboard-current-invoice.table.header.date') }}
						</th>
						<th class="pr-3 font-medium">
							{{ t('account-dashboard-current-invoice.table.header.id') }}
						</th>
						<th class="pr-3 font-medium">
							{{ t('account-dashboard-current-invoice.table.header.amount') }}
						</th>
						<th class="pr-3 font-medium">
							{{ t('account-dashboard-current-invoice.table.header.due-date') }}
						</th>
						<th class="pr-3 font-medium">
							{{ t('account-dashboard-current-invoice.table.header.invoice') }}
						</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td class="pt-4 pl-6 pr-3 lg:pl-8">
							{{ formatDate(invoice?.createdTime, MSDY) }}
						</td>
						<td class="pt-4 pr-3">
							{{ invoice?.id }}
						</td>
						<td class="pt-4 pr-3">
							{{ toCurrencyString(invoice?.totalAmount) }}
						</td>
						<td class="pt-4 pr-3">
							{{ formatDate(invoice?.dueDate, MSDY) }}
						</td>
						<td class="pt-4 pr-3">
							<NuxtLink :to="`/billing/invoices/${invoice?.id}`">
								<span class="text-mx-orange uppercase font-semibold link-text lg:-ml-3 hover:bg-mx-gray-100 lg:px-3 py-1.5 rounded-lg dark:hover:bg-mx-green-800">
									{{ t('account-dashboard-current-invoice.buttons.view') }}
								</span>
							</NuxtLink>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</template>

<script setup lang="ts">
import type { MappedInvoice } from '@/components/account/invoices/types'
import { RectangleStackIcon } from '@heroicons/vue/24/outline'
import { formatDate, MSDY } from '@/utils/formatDate'
import { toCurrencyString } from '@/utils/formatNumbers'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
const props = defineProps({
	invoice: {
		type: Object as PropType<MappedInvoice | null>,
		required: true
	}
})

const { invoice } = toRefs(props)
</script>
