<template>
	<NuxtLink
		to="/billing/payment-methods"
		class="panel"
	>
		<div class="flex items-center space-x-2">
			<IconDollarSign class="h-6 text-mx-gray-500 dark:text-mx-gray-400" />
			<span class="text-sm uppercase text-mx-gray-500 dark:text-mx-gray-400">
				{{ t('payment-methods.title') }}
			</span>
		</div>
		<FormButton
			:button-text="buttonText"
			class="my-4"
		/>
		<div class="px-6 py-2 -mx-6 lg:-mx-8 border-y-2 bg-mx-gray-100 dark:border-y-mx-green-900 dark:bg-mx-green-800">
			<div v-if="hasPaymentMethods">
				<div
					v-for="method in paymentMethods"
					:key="method.id"
					class="flex items-center my-2"
				>
					<IconBank
						v-if="method?.type === BANK_ACCOUNT"
						class="w-5 mr-2 -mt-1 min-w-5"
					/>
					<IconCreditCard
						v-else-if="method?.type === CREDIT_CARD"
						class="w-5 mt-0.5 mr-2 min-w-5"
					/>
					<div class="flex flex-col">
						<div class="flex justify-between">
							<span>
								{{ getPaymentMethodDisplayText(method) }}
							</span>
							<span
								v-if="method.preferred"
								class="ml-8 text-mx-orange"
							>
								{{ t('payment-methods.manage.primary') }}
							</span>
						</div>
						<span
							v-if="method.type === CREDIT_CARD"
							class="text-sm font-bold uppercase text-mx-gray-500 dark:text-mx-gray-400"
						>
							exp {{ method.expiration }}
						</span>
					</div>
				</div>
			</div>
			<div v-else>
				<p class="text-mx-gray-500 dark:text-mx-gray-400">
					{{ t('payment-methods.manage.empty') }}
				</p>
			</div>
		</div>
	</NuxtLink>
</template>

<script setup lang="ts">
import type { PaymentMethod } from '@/components/account/paymentMethods/types'
import { CREDIT_CARD, BANK_ACCOUNT } from '@/constants/payment'
import { usePaymentMethods } from '@/composables/usePaymentMethods'
import { useI18n } from 'vue-i18n'

const { getPaymentMethodDisplayText } = usePaymentMethods()
const { t } = useI18n()

const props = defineProps({
	paymentMethods: {
		type: Object as PropType<PaymentMethod[]>,
		required: true
	}
})
const { paymentMethods } = toRefs(props)
const hasPaymentMethods = computed(() => paymentMethods.value?.length)
const buttonText = computed(() => hasPaymentMethods.value ? t('payment-methods.manage.button.manage') : t('payment-methods.manage.button.add'))
</script>
